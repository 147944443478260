import React from "react"

import Layout from "../../layout/Layout"
import SEO from "../../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Tiwi Islands" />

    <>
      <h1>Tiwi Islands</h1>
    </>
  </Layout>
)

export default Page
